import '../styles/Footer.scss';
import image_footer from '../assets/images/Car_hold.jpg';
import { useNavigate } from "react-router-dom";
import { GrInstagram, GrFacebookOption } from "react-icons/gr";
import Counter from '../pages/Counter';

const Footer = () => {

    const navigate = useNavigate();

    const goToHome = () => {
        navigate("/");

        window.location.href = "/";
    }

    const scrollToSection = (sectionId) => {
        const section = document.getElementById(sectionId);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
        }
    };

    const handleIconClick = (type) => {
        let url = '(https://www.dinogoldinternational.com)';

        switch (type) {
            case 'Facebook':
                url = 'https://www.facebook.com/dinogoldinternational/';
                window.open(url, '_blank');
                break;
            case 'Instagram':
                url = 'https://www.instagram.com/dgi_detailing/';
                window.open(url, '_blank');
                break;
            default:
                break;
        }
    };

    return (
        <>
            <footer>
                <div className='image'>
                    <img src={image_footer} alt='Dino Gold International'></img>
                </div>
                <div className='menu'>
                    <h3>Doormat Navigation</h3>
                    <ul>
                        <li><h4 onClick={() => goToHome()}>Home</h4></li>
                        <li><h4 onClick={() => scrollToSection('about')}>About</h4></li>
                        <li><h4 onClick={() => scrollToSection('services')}>Services</h4></li>
                        <li><h4 onClick={() => scrollToSection('pricing')}>Prices</h4></li>
                        <li><h4 onClick={() => scrollToSection('contact')}>Book now</h4></li>
                    </ul>
                </div>
                <div className='social'>
                    <h3>Social Media</h3>
                    <ul className='social-media'>
                        <li>
                            <GrFacebookOption className='icon' onClick={() => handleIconClick('Facebook')} />
                        </li>
                        <li>
                            <GrInstagram className='icon' onClick={() => handleIconClick('Instagram')} />
                        </li>
                        {/* <li>
                            <TiSocialGithub className='icon'/>
                        </li> */}
                        <li style={{display : 'none'}}>
                            <Counter/>
                        </li>
                    </ul>
                </div>
            </footer>
            <div className='copyright'>
                <h5>© 2024 Dino Gold International. All Rights Reserved. </h5>
            </div>
        </>
    )
}

export default Footer;